<template>
  <div class="welfare">
    <img
      class="banner"
      src="https://oss.pinmallzj.com/image/maintain/2022/08/15/657ea13c5e7c4e639f62cb1207f50fdf467j03vv1a.png"
      alt=""
    />
    <div class="plate2">
      <div class="title">员工福利面临的三大难题</div>
      <div class="title1">解决难题优化领取体验，使员工关怀轻松传递</div>
      <div class="plate2-box">
        <img
          src="https://oss.pinmallzj.com/crmebimage/maintain/2022/07/14/634533246a164853995a5bc1917806d3v4ffsreq66.png"
          alt=""
        />
        <div class="right-box">
          <div class="box-item">
            <div class="circle">1</div>
            <div class="text">
              <p class="text1">员工个性需求难满足</p>
              <p class="text2">
                员工个性鲜明，需求多样，单一福利难以满足多样化需求。
              </p>
            </div>
          </div>
          <div class="box-item">
            <div class="circle">2</div>
            <div class="text">
              <p class="text1">企业采购发放高成本</p>
              <p class="text2">
                传统福利商品采购、仓储、物流、人工成本高。企业很难寻找到货源。
              </p>
            </div>
          </div>
          <div class="box-item">
            <div class="circle">3</div>
            <div class="text">
              <p class="text1">企业文化关怀难传递</p>
              <p class="text2">传统福利商品单一，企业文化及关怀传达生硬。</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="plate1">
      <div class="title">为企业提供员工福利一体化解决方案</div>
      <div class="title1">简单化后台操作，提高运营效率</div>
      <div class="solve-box">
        <div class="solve-item">
          <div class="text">
            <p>搭建企业员工福利商城</p>
            <p>
              电商化体验、创建属于自己的商城，为员工创造便利，为公司创造收益。
            </p>
          </div>
          <img src="@/assets/imgs/icon_plan_welfareshop@2x.png" alt="" />
        </div>
        <div class="solve-item">
          <div class="text">
            <p>全品类商品，应有尽有</p>
            <p>
              拥有上千家大牌企业商品，网红流量款、明星单品、企业推荐等，只选品质好、性价比高的商品。
            </p>
          </div>
          <img src="@/assets/imgs/icon_plan_allshop@2x.png" alt="" />
        </div>
        <div class="solve-item">
          <div class="text">
            <p>直击源头厂家，价格更优惠</p>
            <p>1000+优质供应商，平台长期合作关系，直击底价，不挣任何差价。</p>
          </div>
          <img src="@/assets/imgs/icon_plan_supplier@2x.png" alt="" />
        </div>
        <div class="solve-item">
          <div class="text">
            <p>开发福利系统</p>
            <p>打造礼品官网，满足不同用户场景需求，体验更流畅，展示更快速。</p>
          </div>
          <img src="@/assets/imgs/icon_plan_system@2x.png" alt="" />
        </div>
      </div>
    </div>
    <div class="plate2">
      <div class="title">流程简单、礼品选择化</div>
      <div class="title1">搭建自己的商城，简化采购流程，提升采购效率</div>
      <img src="@/assets/imgs/img_plan_process@2x.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "welfare",
};
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
}

.plate1 {
  background: #fafbfc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  img {
    width: 60%;
    margin-top: 40px;
  }

  .title {
    margin-top: 80px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    color: #000000;
  }

  .title1 {
    margin-top: 10px;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
}
.plate2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  img {
    width: 70%;
    margin-top: 40px;
  }

  .title {
    margin-top: 80px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    color: #000000;
  }

  .title1 {
    margin-top: 10px;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
  }
}

.plate2-box {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 479px;
    height: 367px;
  }

  .right-box {
    padding-left: 40px;

    .box-item {
      margin: 60px 0;
      display: flex;
      align-items: center;

      .circle {
        width: 37px;
        height: 37px;
        text-align: center;
        line-height: 37px;
        background: linear-gradient(126deg, #0978ff 0%, #5aa9ff 100%);
        border-radius: 50%;
        font-size: 19px;
        font-weight: 500;
        color: #ffffff;
      }

      .text {
        margin-left: 16px;

        .text1 {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          color: #333333;
        }

        .text2 {
          margin-top: 8px;
          font-size: 13px;
          line-height: 22px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}

.solve-box {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .solve-item:hover {
    box-shadow: 0px 0px 16px 1px rgba(7, 20, 51, 0.14);
  }

  .solve-item {
    transition: all 0.4s;
    cursor: pointer;
    padding: 32px 22px;
    width: 471px;
    height: 144px;
    margin-right: 68px;
    margin-top: 16px;
    background: #fff;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 0 0 30px;
      width: 80px;
      height: 80px;
    }

    .text {
      p:nth-child(1) {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }

      p:nth-child(2) {
        margin-top: 16px;
        width: 279px;
        height: 38px;
        line-height: 22px;
        font-size: 11px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>